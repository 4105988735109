import React from 'react'
import * as Bs from "react-bootstrap"
import { graphql, useStaticQuery } from 'gatsby'
import ReactStars from "react-rating-stars-component";

function TestimonialBlock(props) {

  

  const firstExample = {
    size: 18,
    value: 5,
    color: "rgb(33, 37, 41)",
    activeColor: "#ffc107",
    edit: false,
  };

  
  return (
    <section id='testimonial'>

      <Bs.Container style={{marginTop: 70, marginBottom: 10}}>
          <Bs.Row style={{maxWidth: 1000, margin: 'auto'}}>
              <Bs.Col>
                  <div style={{maxWidth: 100, margin: 'auto'}}> <ReactStars {...firstExample} /></div>
                  <div style={{textAlign: 'center', fontStyle: "italic", color: 'white', fontWeight: 300, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}}>"Kohdao changed our business"</div>
                  </Bs.Col>
              <Bs.Col>
              <div style={{maxWidth: 100, margin: 'auto'}}> <ReactStars {...firstExample} /></div>
                  <div style={{textAlign: 'center', fontStyle: "italic", color: 'white', fontWeight: 300, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}}>"Daily leads X2! Thank you"</div>
              </Bs.Col>
              <Bs.Col>
              <div style={{maxWidth: 100, margin: 'auto'}}> <ReactStars {...firstExample} /></div>
                  <div style={{textAlign: 'center', fontStyle: "italic", color: 'white', fontWeight: 300, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}}>"We can now focus on sales!</div>
              </Bs.Col>
          </Bs.Row>
      </Bs.Container>
    </section>
  )
}

export default TestimonialBlock