import styled from 'styled-components'

export const TextBlock = styled.div`
text-align: justify;
padding-left: 27px;
padding-right: 27px;
max-width: 1140px;
margin: auto;

h1{
  margin-top: 100px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -2.5px
}
h2{
  font-weight: 700;
  font-size: 40px;
  text-align: left;
  margin-bottom: 30px;
}

h3{
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 32px;
  text-align: left;
}


p{
  text-align: justify;
  margin-bottom: 50px;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 38px;
}

ul{
  text-align: justify;
  margin-bottom: 50px;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 38px;
}

li {
  text-align: justify;
  margin-bottom: 0px;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 38px;
}
`

export default TextBlock;